/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'send-x': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M15.964.686a.5.5 0 00-.65-.65L.767 5.855a.75.75 0 00-.124 1.329l4.995 3.178 1.531 2.406a.5.5 0 00.844-.536L6.637 10.07l7.494-7.494-1.895 4.738a.5.5 0 10.928.372zm-2.54 1.183L5.93 9.363 1.591 6.602z"/><path pid="1" d="M16 12.5a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0m-4.854-1.354a.5.5 0 000 .708l.647.646-.647.646a.5.5 0 00.708.708l.646-.647.646.647a.5.5 0 00.708-.708l-.647-.646.647-.646a.5.5 0 00-.708-.708l-.646.647-.646-.647a.5.5 0 00-.708 0"/>',
    },
});
